@import '../../../../../styles/colors.css';

.repoRow {
  background-color: var(--color-dazn-tarmac-light-40);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 4px;
}

.repoRow:nth-child(even) {
  background-color: var(--color-dazn-tarmac-light-10);
}

.repoLink {
  color: var(--color-dazn-tarmac-light-80);
  text-decoration: none;
  font-size: 15px;
  text-align: left;
}

.repoLink:hover,
.repoLink:focus {
  text-decoration: underline;
}

.repoCancelButton {
  cursor: pointer;
  padding: 5px;
  border: none;
  border-radius: 3px;
  background: none;
  color: var(--color-dazn-tarmac-light-80);
}

.repoCancelButton:hover,
.repoCancelButton:focus {
  background: var(--color-dazn-tarmac-light-80);
  color: var(--color-dazn-tarmac-light-10);
}
