.loaderContainer {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
}

.loaderText {
  margin-top: 20px;
  color: var(--color-white);
}
.loaderBg {
  opacity: 0.6;
  background-color: var(--color-dazn-tarmac);
  position: absolute;
  height: 100vh;
  width: 100vw;
}
