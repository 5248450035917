@import '../../../../styles/colors.css';

.container {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  width: 220px;
  overflow: hidden auto;
}

.containerTop {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-left: 10px;
}

.containerBottom {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-left: 10px;
  color: white;
}

.reposContainer {
  margin-top: 20px;
  padding: 0;
  list-style: none;
}

.repoContainer {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  margin-top: 10px;
  border-top: 1px solid var(--color-dazn-tarmac-light-10);
}

.repoInput {
  box-sizing: border-box;
  background: #fff;
  padding: 5px;
  width: 125px;
  margin-right: 10px;
}

.action {
  align-items: center;
  width: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
}

.reloadButton {
  height: 30px;
  text-transform: uppercase;
  cursor: pointer;
  background-color: var(--color-dx-cyan);
  border: 1px solid var(--color-dazn-tarmac);
  padding: 5px 10px;
  color: var(--color-black);
}

.animateSpin {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
  height: 30px;
  text-transform: uppercase;
  cursor: pointer;
  background-color: var(--color-dx-cyan);
  border: 1px solid var(--color-dazn-tarmac);
  padding: 5px 10px;
  color: var(--color-black);
}
