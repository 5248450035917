:root {
  --color-white: #fff;
  --color-black: #000;
  --color-dazn-tarmac: #0c161c;
  --color-dazn-tarmac-light-10: #242d33;
  --color-dazn-tarmac-light-20: #3d4549;
  --color-dazn-tarmac-light-40: #6d7377;
  --color-dazn-tarmac-light-60: #9ea2a4;
  --color-dazn-tarmac-light-70: #b6b9bb;
  --color-dazn-tarmac-light-80: #ced0d2;
  --color-dazn-chalk: #f9fafa;
  --color-dazn-yellow-dark-10: #dee517;
  --color-dazn-yellow: #f7ff1a;
  --color-dazn-yellow-light-50: #fbff8c;
  --color-dazn-danger: #fd7777;
  --color-dazn-blue: #0070ba;
  --color-dazn-blue-dark-20: #005994;
  --color-dazn-green: #14854d;
  --color-dazn-red: #d12424;
  --color-dx-cyan: #00c2ff;
  --color-dx-pink: #f22dae;
}
