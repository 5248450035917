@import '../../../../styles/colors.css';
.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--color-dazn-tarmac-light-10);
}

.panelContainer {
  border-left: 3px solid var(--color-dx-cyan);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
