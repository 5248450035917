.container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  height: 100%;
  background-color: var(--color-dazn-tarmac);
}

.title {
  font-size: 14px;
}

.input {
  margin: 10px 0px;
  width: 200px;
  padding: 8px 10px;
}

.button {
  height: 30px;
  text-transform: uppercase;
  cursor: pointer;
  background-color: var(--color-dazn-yellow);
  border: 1px solid var(--color-dazn-tarmac);
  padding: 5px 10px;
  color: var(--color-black);
}
