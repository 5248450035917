@import '../../../../styles/colors.css';

.card {
  background-color: var(--color-dazn-tarmac);
  display: flex;
  margin: 5px 10px;
  border: 1px solid var(--color-dazn-tarmac-light-80);
  border-radius: 8px;
  flex-direction: column;
  text-decoration: none;
  padding: 10px;
  box-sizing: border-box;
  color: var(--color-dazn-chalk);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.cardRepo {
  font-size: 10px;
  padding-bottom: 5px;
}

.cardTitle {
  font-size: 14px;
  font-weight: 600;
}

.cardFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 0;
}

.cardDate {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.cardDateAgo {
  color: var(--color-dx-pink);
  font-size: 16px;
  font-weight: 600;
  font-style: italic;
}

.fileDiff {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-left: 6px;
}
.fileDiff span {
  margin-right: 5px;
}

.cardDateCreated {
  font-size: 10px;
}

.cardApprovalsTitle {
  display: flex;
  align-items: center;
  margin: 0;
}

.cardApprovalsTitle .commentIcon {
  color: rgb(139, 148, 158);
  fill: rgb(139, 148, 158);
  margin: 0 5px;
}

.cardApprovalsTitle .approvalIcon {
  color: rgb(86, 211, 100);
  fill: rgb(86, 211, 100);
  margin: 0 5px;
}

.cardApprovalsTitle .changesRequestedIcon {
  color: rgb(248, 81, 73);
  fill: rgb(248, 81, 73);
  margin: 0 5px;
}

.cardApprovals {
  display: flex;
  flex-direction: row;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 10px;
  margin-left: 10px;
}

.owner {
  margin: 10px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.userName {
  font-size: 12px;
}

.cardReviewer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
