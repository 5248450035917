@font-face {
  font-family: 'DAZNOscine';
  src: url('../assets/fonts/DAZN_Oscine_W_Rg.eot');
  src: url('../assets/fonts/DAZN_Oscine_W_Rg.eot') format('embedded-opentype'),
    url('../assets/fonts/DAZN_Oscine_W_Rg.ttf') format('truetype'),
    url('../assets/fonts/DAZN_Oscine_W_Rg.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DAZNOscine';
  src: url('../assets/fonts/DAZN_Oscine_W_It.eot');
  src: url('../assets/fonts/DAZN_Oscine_W_It.eot') format('embedded-opentype'),
    url('../assets/fonts/DAZN_Oscine_W_It.ttf') format('truetype'),
    url('../assets/fonts/DAZN_Oscine_W_It.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'DAZNOscine';
  src: url('../assets/fonts/DAZN_Oscine_W_Bd.eot');
  src: url('../assets/fonts/DAZN_Oscine_W_Bd.eot') format('embedded-opentype'),
    url('../assets/fonts/DAZN_Oscine_W_Bd.ttf') format('truetype'),
    url('../assets/fonts/DAZN_Oscine_W_Bd.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'DAZNOscine';
  src: url('../assets/fonts/DAZN_Oscine_W_BdIt.eot');
  src: url('../assets/fonts/DAZN_Oscine_W_BdIt.eot') format('embedded-opentype'),
    url('../assets/fonts/DAZN_Oscine_W_BdIt.ttf') format('truetype'),
    url('../assets/fonts/DAZN_Oscine_W_BdIt.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'DAZNOscine';
  src: url('../assets/fonts/DAZN_Oscine_W_XBd.eot');
  src: url('../assets/fonts/DAZN_Oscine_W_XBd.eot') format('embedded-opentype'),
    url('../assets/fonts/DAZN_Oscine_W_XBd.ttf') format('truetype'),
    url('../assets/fonts/DAZN_Oscine_W_XBd.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

/* stylelint-disable indentation */
@font-face {
  font-family: 'DAZNOscine';
  src: url('../assets/fonts/DAZN_Oscine_W_XBdIt.eot');
  src: url('../assets/fonts/DAZN_Oscine_W_XBdIt.eot')
      format('embedded-opentype'),
    url('../assets/fonts/DAZN_Oscine_W_XBdIt.ttf') format('truetype'),
    url('../assets/fonts/DAZN_Oscine_W_XBdIt.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'trim-bold';
  src: url('../assets/fonts/TrimDAZNHeader-Bold.eot');
  src: url('../assets/fonts/TrimDAZNHeader-Bold.eot')
      format('embedded-opentype'),
    url('../assets/fonts/TrimDAZNHeader-Bold.ttf') format('truetype'),
    url('../assets/fonts/TrimDAZNHeader-Bold.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'trim-bold';
  src: url('../assets/fonts/TrimDAZNHeader-BoldItalic.eot');
  src: url('../assets/fonts/TrimDAZNHeader-BoldItalic.eot')
      format('embedded-opentype'),
    url('../assets/fonts/TrimDAZNHeader-BoldItalic.ttf') format('truetype'),
    url('../assets/fonts/TrimDAZNHeader-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
