.columnsContainer {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-grow: 1;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
  background-color: var(--color-dazn-tarmac);
}

.column {
  background-color: var(--color-dazn-tarmac-light-10);
  height: 100%;
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-dazn-tarmac);
  flex-grow: 1;
  flex-basis: 0;
}

.columnTitle {
  background-color: var(--color-dx-cyan);
  font-weight: 600;
  text-align: center;
  font-size: 14px;
  padding: 10px;
  color: var(--color-dazn-tarmac);
  margin-bottom: 5px;
}

.titleCount {
  padding-left: 5px;
  font-weight: 700;
  font-size: 14px;
}

.columnRows {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden auto;
}
